import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { SET_MIGRATE_EXISTING_USER_TO_AAD_RESULT, SET_ISLOGIN_USER_MSAUSER } from "./EnforceAADLogin.actions";
import { fromJS } from "immutable";

export const setMigrateExistingUserToAADResult = createReducer(null, {
	[SET_MIGRATE_EXISTING_USER_TO_AAD_RESULT](state, { migrateExistingUserToAADResult }) {
		return migrateExistingUserToAADResult;
	}
});

export const setIsLoginUserMSAUserResult = createReducer(null, {
	[SET_ISLOGIN_USER_MSAUSER](state, { msaDetails }) {
		return msaDetails;
	}
});
