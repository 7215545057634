import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";

export const GET_SETTING_DATA = "components/Setting/GET_SETTING_DATA";
export const getSettingData = makeActionCreator(GET_SETTING_DATA, false);

export const SET_SETTING_DATA = "components/Setting/SET_SETTING_DATA";
export const setSettingData = makeActionCreator(SET_SETTING_DATA, false, "businessFlagsEntity");

export const SUBMIT_SETTING_DATA = "components/Setting/SUBMIT_SETTING_DATA";
export const submitSettingData = makeActionCreator(SUBMIT_SETTING_DATA, false, "businessFlagsEntity");
