import { DateUtils } from "@ec-oem/ec.oem.oa3.ui.common/utility/dateUtils";

export class AnnouncementResultData {
	constructor(responseData) {
		this.Records = responseData.Records;
	}
}
export class AnnouncementData {
	constructor(announcementData) {
		this.MessageId = announcementData.MessageId;
		this.MessageDesc = announcementData.MessageDesc ? announcementData.MessageDesc : "";
		this.StartDateTime = announcementData.StartDateTime ? announcementData.StartDateTime : "";
		this.EndDateTime = announcementData.EndDateTime ? announcementData.EndDateTime : "";
		this.CreatedDateTime = announcementData.CreatedDateTime ? announcementData.CreatedDateTime : "";
		this.LastModifiedBy = announcementData.LastModifiedBy ? announcementData.LastModifiedBy : "";
		this.LastModifiedDateTime = announcementData.LastModifiedDateTime ? announcementData.LastModifiedDateTime : "";
		this.MessageText = announcementData.MessageText ? announcementData.MessageText : "";
		this.MessageTypeId = announcementData.MessageTypeId ? announcementData.MessageTypeId : "";
		this.Title = announcementData.Title ? announcementData.Title : "";
		this.Summary = announcementData.Summary ? announcementData.Summary : "";
		this.AdditionalDetails = announcementData.AdditionalDetails ? announcementData.AdditionalDetails : "";
		this.ToolsAndServicesImpacted = announcementData.ToolsAndServicesImpacted ? announcementData.ToolsAndServicesImpacted : "";
		this.ImpactsAndLimitations = announcementData.ImpactsAndLimitations ? announcementData.ImpactsAndLimitations : "";
	}
}

export const announcementMapper = (announcementData) => {
	if (announcementData) {
		return new AnnouncementData({
			MessageId: announcementData.messageId,
			MessageDesc: announcementData.messageDesc ? announcementData.messageDesc : "",
			StartDateTime: announcementData.notificationStartDateTime ? DateUtils.get_UTCDateOnly(announcementData.notificationStartDateTime) : "",
			EndDateTime: announcementData.notificationEndDateTime ? DateUtils.get_UTCDateOnly(announcementData.notificationEndDateTime) : "",
			CreatedDateTime: announcementData.createdDateTime ? announcementData.createdDateTime : "",
			LastModifiedBy: announcementData.lastModifiedBy ? announcementData.lastModifiedBy : "",
			LastModifiedDateTime: announcementData.lastModifiedDateTime ? announcementData.lastModifiedDateTime : "",
			MessageText: announcementData.messageText ? announcementData.messageText : "",
			MessageTypeId: announcementData.messageTypeId ? announcementData.messageTypeId : "",
			Title: announcementData.title ? announcementData.title : "",
			Summary: announcementData.summary ? announcementData.summary : "",
			AdditionalDetails: announcementData.additionalDetails ? announcementData.additionalDetails : "",
			ToolsAndServicesImpacted: announcementData.toolsAndServicesImpacted ? announcementData.toolsAndServicesImpacted : "",
			ImpactsAndLimitations: announcementData.impactsAndLimitations ? announcementData.impactsAndLimitations : ""
		});
	} else return null;
};
