import ApplicationConfig from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { throwException } from "@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions";
import {
	SUBMIT_MIGRATE_EXISTING_USER_TO_AAD_DATA,
	CHECK_IS_TID_MSA_ACCOUNT,
	setMigrateExistingUserToAADResult,
	setIsLoginUserMSAUserResult
} from "./EnforceAADLogin.actions";
import history from "@ec-oem/ec.oem.oa3.ui.common/utility/history";
import { take, put, call, apply } from "redux-saga/effects";
import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.common/utility";
import {
	FieldConstants,
	MSARetiralFieldConstants,
	MSARetiralFIELDLENGTHS,
	Hour,
	Minute,
	TimeDurations,
	AutoSubmitCBRValues
} from "../../constants/SettingConstant";

export function* CheckIsTidMSAAccountSaga() {
	while (true) {
		try {
			let { UserInfoData } = yield take(CHECK_IS_TID_MSA_ACCOUNT);
			const config = yield ApplicationConfig.commonConfig;
			let isMSAUser = "false";
			let whitelistedMSAAccounts = config.MSARetiral.WhitelistedMSAAccounts.toLowerCase().split(",");
			let isUserNotInWhiteList = whitelistedMSAAccounts.indexOf(UserInfoData.user.displayableId.toLowerCase()) === -1;
			let msaDetails = {
				"isMSAUser": isMSAUser,
				"MSAMigrationDPCPortal": config.MSARetiral.MSAMigrationDPCPortal,
				"MSAMigrationAADHelpDocument": config.MSARetiral.MSAMigrationAADHelpDocument,
				"EnforcementDate": config.MSARetiral.EnforcementDate
			};
			if (
				config.MSARetiral &&
				config.MSARetiral.IsFeatureEnabled &&
				config.MSARetiral.IsFeatureEnabled === true &&
				UserInfoData.user.idToken.tid === config.MSARetiral.TIDToBeRetired &&
				isUserNotInWhiteList
			) {
				isMSAUser = "true";
				msaDetails = {
					"isMSAUser": isMSAUser,
					"MSAMigrationDPCPortal": config.MSARetiral.MSAMigrationDPCPortal,
					"MSAMigrationAADHelpDocument": config.MSARetiral.MSAMigrationAADHelpDocument,
					"EnforcementDate": config.MSARetiral.EnforcementDate
				};
				yield put(setIsLoginUserMSAUserResult(msaDetails));
				history.push("/EnforceAADLogin");
			} else {
				yield put(setIsLoginUserMSAUserResult(msaDetails));
			}
		} catch (error) {
			yield put(throwException("CheckIsTidMSAAccountSaga error: " + error));
		}
	}
}

export function* migrateExistingUserToAAD() {
	while (true) {
		try {
			let { apiaadMigrationData } = yield take(SUBMIT_MIGRATE_EXISTING_USER_TO_AAD_DATA);
			let requestData = JSON.stringify(getAADMigrationObject(apiaadMigrationData));

			const config = yield ApplicationConfig.commonConfig;
			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.MSARetiral.CustomerMasterAPIUrl}/api/CustomerMaster/UpdateEmail`,
					method: "POST",
					data: requestData
				},
				SagaHandleResponse: { HandleResponse: true, ExcludedResponses: [400] }
			});
			let responseData = { apistatus: 0, ValidationMessages: "" };
			switch (response.status) {
				case 200:
					responseData.apistatus = response.status;
					yield put(setMigrateExistingUserToAADResult(responseData));
					break;
				case 400:
					let ValidationMessages = yield apply(response, response.json);
					responseData.ValidationMessages = ValidationMessages;
					responseData.apistatus = response.status;
					responseData.toggle400Error = true;
					if (responseData && responseData.ValidationMessages.length > 0) {
						yield put(setMigrateExistingUserToAADResult(responseData));
					}
					break;
				default:
					yield put(throwException("migrateExistingUserToAAD error: ", null, response));
					break;
			}
		} catch (error) {
			yield put(throwException("migrateExistingUserToAAD error: " + error));
		}
	}
}

function getAADMigrationObject(apiaadMigrationData) {
	return {
		"newEmail": apiaadMigrationData[MSARetiralFieldConstants.AAD_LOGIN_CREDENTIALS],
		"appAuthFullTicket": apiaadMigrationData[MSARetiralFieldConstants.FULL_TICKET_DATA]
	};
}
function mockmigrateExistingUserToAAD(apiaadMigrationData) {
	// let response = {
	// 	status: 200,
	// 	statusCode: "SUCCESS",
	// 	ValidationMessages: [""]
	// };

	let response = {
		status: 400,
		statusCode: "FAILURE",
		ValidationMessages: [
			"The existing account is either suspended or was not onboarded previuosly to DOC",
			"No Valid AAD Tenant information found. This may happen if there are no active subscriptions for the tenant.",
			"Check to make sure you have correct tenant ID.",
			"Check your subscription with your subscription administrator."
		]
	};

	return response;
}
