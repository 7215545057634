import { take, put, call, apply } from "redux-saga/effects";
import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.common/utility";
import * as AnnouncementAction from "./Announcement.actions";
import ApplicationConfig from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { AnnouncementResultData, announcementMapper } from "../../models";
import { throwException } from "@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions";
import { RequestHeaderConstants } from "@ec-oem/ec.oem.oa3.ui.common/constants";

const getAnnouncementData = (announcementitems) => {
	return announcementitems.map((item) => {
		return announcementMapper(item);
	});
};

export function* getAllAnnouncementSaga() {
	while (true) {
		try {
			yield take(AnnouncementAction.GET_ALL_ANNOUNCEMENT);

			const config = yield ApplicationConfig.commonConfig;
			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.oa_api.url}/user/announcements`,
					method: "GET",
					RequestHeaders: [
						{
							key: [RequestHeaderConstants.SubscriptionKey],
							value: `${config.oa_api.Subscriptions_Headers.subscription_id};${config.oa_api.Subscriptions_Headers.subscription_portal_services}`
						}
					]
				},
				SagaHandleResponse: { HandleResponse: true },
				IsbackGroundTask: true
			});
			if (response.status == 200) {
				const data = yield apply(response, response.json);
				if (data.length > 0) {
					let announcementDetails = new AnnouncementResultData({
						Records: getAnnouncementData(data)
					});
					yield put(AnnouncementAction.setAllAnnouncement(announcementDetails));
				} else {
					let announcementDetails = new AnnouncementResultData({
						Records: []
					});
					yield put(AnnouncementAction.setAllAnnouncement(announcementDetails));
				}
			} else {
				yield put(AnnouncementAction.setAllAnnouncement(null));
			}
		} catch (error) {
			yield put(throwException("getAllAnnouncementSaga error: " + error));
			yield put(AnnouncementAction.setAllAnnouncement(null));
		}
	}
}
