import { take, put, call, apply, select } from "redux-saga/effects";
import * as SettingAction from "./Setting.action";
import { BusinessFlagsEntity } from "../../common/models/BusinessFlagsEntity";
import Setting from "./SettingData.json";
import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.common/utility";
import ApplicationConfig from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { throwException } from "@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions";
import { showAlert } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { AlertsTypes } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import { UPDATE_SELECTED_BUSINESS_MODEL } from "@ec-oem/ec.oem.oa3.ui.common/actions";
import { Message } from "../../constants/SettingConstant";
import { selectedBusinessModelSelector } from "@ec-oem/ec.oem.oa3.ui.common/selectors";

export function* getUpdatedBusinessModelSaga() {
	while (true) {
		try {
			yield take(UPDATE_SELECTED_BUSINESS_MODEL);
			yield put(SettingAction.setSettingData(null));
			yield put(SettingAction.getSettingData());
		} catch (error) {
			yield put(throwException("getUpdatedBusinessModelSaga error: " + error));
		}
	}
}

export function* saveSettingDataSaga() {
	while (true) {
		try {
			let { businessFlagsEntity } = yield take(SettingAction.SUBMIT_SETTING_DATA);
			const config = yield ApplicationConfig.commonConfig;
			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.mdos_api_path}/SystemService/SetBusinessFlagsAsync`,
					method: "POST",
					data: JSON.stringify(businessFlagsEntity),
					IsMdosCall: true
				},
				IsBackgroundTask: true,
				SagaHandleResponse: { HandleResponse: true }
			});

			if (response.status === 200) {
				const savedSettingResponse = yield apply(response, response.json);
				if (savedSettingResponse) {
					let successMsg = Message.SUCCESSMESSGAE;
					yield put(showAlert({ type: AlertsTypes.SUCCESS, message: successMsg }));
				}
			}
		} catch (error) {
			yield put(throwException("saveSettingDataSaga error: " + error));
		}
	}
}

export function* getSettingDataSaga() {
	while (true) {
		try {
			yield take(SettingAction.GET_SETTING_DATA);
			const config = yield ApplicationConfig.commonConfig;
			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.mdos_api_path}/SystemService/GetActiveBusinessFlags`,
					method: "GET",
					IsMdosCall: true
				},
				IsBackgroundTask: true,
				SagaHandleResponse: { HandleResponse: true }
			});

			if (response.status === 200) {
				const saveSettingResponse = yield apply(response, response.json);
				if (saveSettingResponse != null) {
					let businessFlagsEntity = new BusinessFlagsEntity(saveSettingResponse);
					yield put(SettingAction.setSettingData(businessFlagsEntity));
				}
			} else {
				yield put(SettingAction.setSettingData(null));
			}
		} catch (error) {
			yield put(throwException("getSettingDataSaga error: " + error));
			yield put(SettingAction.setSettingData(null));
		}
	}
}
