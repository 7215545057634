import { DefaultButton, Label, PrimaryButton, Overlay, Stack, StackItem, IconButton, Text, FocusTrapZone } from "@fluentui/react";
import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCount } from "../ChatBot.selectors";
import { userInfoSelector } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { addMessageItem, getCosmicResponse, setConversationAllowed } from "../ChatBot.actions";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react";
import "./Support.scss"

const dialogContentProps = {
	type: DialogType.normal,
	title: "Create Support Ticket",
	closeButtonAriaLabel: "Close",
	subText: "Proceed to create a support ticket with OEM Support Team?"
};

class Support extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showmodal: false
		};
		this.openDialog = this.openDialog.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	openDialog = () => {
		this.setState({ showmodal: true });
	};

	closeDialog = () => {
		this.setState({ showmodal: false });
	};

	onSubmit = () => {
		this.props.actions.addMessageItem(null);
		this.props.actions.setConversationAllowed(false);
		this.setState({ showmodal: false });
		this.props.actions.getCosmicResponse();
	};

	render() {
		return (
			<div>
				{/* <Label className="label-text">
                {"The Chat interface is in Beta phase and is continuously learning and improving the scope of tickets it can resolve."}
                </Label> */}
				<DefaultButton onClick={this.openDialog} className="cosmic-button" text="Create Support Ticket" />
				{this.state.showmodal && <Overlay
					isBlocking={true}
					className ={"support-overlay-modal"}
				>
					<FocusTrapZone ariaLabelledBy="supportOverlayCreateSupport">
						<div className="support-overlay-content">
							<Stack horizontal className="suuppor-header-stack padd-bottom10">
								<StackItem id="supportOverlayCreateSupport" className="support-ticket-title">
									{this.props.intl.formatMessage({
										id: "supportOverlay.createsupportticket",
										defaultMessage: "Create Support Ticket"
									})}
								</StackItem>
								<StackItem>
									<IconButton
										iconProps={{ iconName: "Cancel" }}
										ariaLabel="Close Create Support Ticket popup"
										onClick={(event, value) => {
											this.setState({ showmodal: false });
										}}
									/>
								</StackItem>
							</Stack>
							<Stack className="padd-bottom10"><Text>{this.props.intl.formatMessage({ id: "supportOverlay.message", defaultMessage: "Proceed to create a support ticket with OEM Support Team?" })}</Text></Stack>
							<Stack horizontal className="support-overlay-buttons-stack">
								<StackItem className="button-stack-item submit">
									<PrimaryButton
										primary
										allowDisabledFocus
										onClick={this.onSubmit}
										ariaLabel={this.props.intl.formatMessage({ id: "supportOverlay.Submit", defaultMessage: "Create Ticket" })}>
										{this.props.intl.formatMessage({ id: "supportOverlay.Submit", defaultMessage: "Create Ticket" })}
									</PrimaryButton>
								</StackItem>
								<StackItem className="button-stack-item cancel">
									<DefaultButton
										allowDisabledFocus
										onClick={this.closeDialog}
										ariaLabel={this.props.intl.formatMessage({ id: "supportOverlay.Cancel", defaultMessage: "Cancel" })}>
										{this.props.intl.formatMessage({ id: "supportOverlay.Cancel", defaultMessage: "Cancel" })}
									</DefaultButton>
								</StackItem>
							</Stack>
						</div>
					</FocusTrapZone>
					
				{/* <Dialog
					hidden={!this.state.showmodal}
					onDismiss={this.closeDialog}
					dialogContentProps={dialogContentProps}
					modalProps={{
						isBlocking: false,
						styles: { main: { maxWidth: 450 } }
					}}
					// modalProps={modalProps}
				>
					<DialogFooter>
						<PrimaryButton onClick={this.onSubmit} text="Create Ticket" />
						<DefaultButton onClick={this.closeDialog} text="Cancel" />
					</DialogFooter>
				</Dialog> */}
				</Overlay>}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		count: getCount(state),
		userinfo: userInfoSelector(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators({ getCosmicResponse, addMessageItem, setConversationAllowed }, dispatch)
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Support));
