import { take, put, call, apply, select } from "redux-saga/effects";
import * as NotificationAction from "./Notification.actions";
import {
	notificationMapper,
	AlertRequestData,
	UserAlertInformation,
	Notifcation as Notification
} from "@ec-oem/ec.oem.oa3.ui.common/models/Notification";
import { throwException, trackTrace } from "@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions";
import notificationData from "./NotificationData.json";
import ApplicationConfig from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.common/utility";
import { AlertOpretionTypes as AlertOperationTypes } from "@ec-oem/ec.oem.oa3.ui.common/constants";
import { getNotificationPanelData } from "@ec-oem/ec.oem.oa3.ui.common/actions";
import { notificationSelector } from "./Notification.selectors";

const SearchRequestMapper = {
	[AlertOperationTypes.Get_All_Alerts]: (alertRequestData) => {
		return new AlertRequestData(alertRequestData);
	},
	[AlertOperationTypes.Mark_Read_Alerts]: (alertRequestData) => {
		return JSON.stringify(alertRequestData);
	},
	[AlertOperationTypes.Delete_Alerts]: (alertRequestData) => {
		return JSON.stringify(alertRequestData);
	}
};

export function* getAllNotificationsSaga() {
	while (true) {
		try {
			const { alertSearchCriteria } = yield take(NotificationAction.GET_ALL_NOTIFICATIONS);

			const config = yield ApplicationConfig.commonConfig;
			const data = JSON.stringify(SearchRequestMapper[AlertOperationTypes.Get_All_Alerts](alertSearchCriteria));
			const response = yield call(InvokeUrl, {
				Config: {
					url: `${config.mdos_api_path}/OnboardV2/GetAlertsDetails`,
					method: "POST",
					data: data,
					IsMdosCall: true
				},
				SagaHandleResponse: { HandleResponse: true }
			});
			if (response.status === 200) {
				let notificationData = yield apply(response, response.json);
				if (notificationData !== null) {
					yield put(NotificationAction.setAllNotifications(notificationData));
				}
			} else {
				yield put(trackTrace("getAllNotificationSaga response status: " + response.status));
			}
		} catch (error) {
			yield put(throwException("getAllNotificationSaga error: " + error));
		}
	}
}

export function* markAsReadNotificationsSaga() {
	while (true) {
		try {
			const { readAlerts, selectedPageSize } = yield take(NotificationAction.MARKREAD_SELETED_NOTIFICATIONS);
			const data = SearchRequestMapper[AlertOperationTypes.Mark_Read_Alerts](readAlerts);
			const config = yield ApplicationConfig.commonConfig;
			const response = yield call(InvokeUrl, {
				Config: { url: `${config.mdos_api_path}/OnboardV2/UpdateAlertsDetails`, method: "POST", data: data, IsMdosCall: true },
				SagaHandleResponse: { HandleResponse: true }
			});

			if (response.status === 200) {
				var alertRequestData = {
					page: 1,
					pageSize: selectedPageSize
				};
				let { Alerts, ...rest } = yield select(notificationSelector);
				let alertIds = readAlerts.map((x) => x.AlertId);
				Alerts = Alerts.map((notification) => {
					if (alertIds.includes(notification.AlertId)) notification.IsRead = true;
					return notification;
				});
				yield put(NotificationAction.setAllNotifications({ Alerts, ...rest }));
				yield put(getNotificationPanelData());
			} else {
				yield put(trackTrace("markAsReadNotificationsSaga response status: " + response.status));
			}
		} catch (error) {
			yield put(throwException("markAsReadNotificationsSaga error: " + error));
		}
	}
}

export function* deleteNotificationsSaga() {
	while (true) {
		try {
			const { deleteAlerts, selectedPageSize } = yield take(NotificationAction.DELETE_SELETED_NOTIFICATIONS);
			const data = SearchRequestMapper[AlertOperationTypes.Delete_Alerts](deleteAlerts);

			const config = yield ApplicationConfig.commonConfig;
			const response = yield call(InvokeUrl, {
				Config: { url: `${config.mdos_api_path}/OnboardV2/UpdateAlertsDetails`, method: "POST", data: data, IsMdosCall: true },
				SagaHandleResponse: { HandleResponse: true }
			});

			if (response.status === 200) {
				var alertRequestData = {
					page: 1,
					pageSize: selectedPageSize
				};
				let { Alerts, ...rest } = yield select(notificationSelector);
				let alertIds = deleteAlerts.map((x) => x.AlertId);
				Alerts = Alerts.filter((notification) => alertIds.includes(notification.AlertId));
				yield put(NotificationAction.getAllNotifications({ Alerts, ...rest }));
				yield put(getNotificationPanelData());
			} else {
				yield put(trackTrace("deleteNotificationsSaga response status: " + response.status));
			}
		} catch (error) {
			yield put(throwException("deleteNotificationsSaga error: " + error));
		}
	}
}
