import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import {
	ADD_MESSAGES,
	SET_LIST,
	SET_COUNT,
	SET_CONVERSATION_ID,
	SET_ABORT_CONTROLLER,
	SET_CONVERSATION_ALLOWED,
	SET_IS_RESPONSE_STOP_ENABLED,
	SET_KEY_CATEGORIES_LIST
} from "./ChatBot.actions";
import { fromJS } from "immutable";

export const conversationlist = createReducer(null, {
	[ADD_MESSAGES](state, { conversationlist }) {
		return conversationlist ? conversationlist : null;
	}
});

export const messageitemlist = createReducer(null, {
	[SET_LIST](state, { messageitemlist }) {
		return fromJS(messageitemlist);
	}
});

export const keyCategoriesList = createReducer(null, {
	[SET_KEY_CATEGORIES_LIST](state, { keyCategoriesList }) {
		return fromJS(keyCategoriesList);
	}
});

export const count = createReducer(null, {
	[SET_COUNT](state, { count }) {
		return count ? count : null;
	}
});

export const conversationid = createReducer(null, {
	[SET_CONVERSATION_ID](state, { conversationid }) {
		return conversationid ? conversationid : null;
	}
});

export const abortController = createReducer(null, {
	[SET_ABORT_CONTROLLER](state, { abortController }) {
		return fromJS(abortController);
	}
});

export const conversationAllowed = createReducer(null, {
	[SET_CONVERSATION_ALLOWED](state, { conversationAllowed }) {
		return conversationAllowed;
	}
});

export const isResponseStopEnabled = createReducer(null, {
	[SET_IS_RESPONSE_STOP_ENABLED](state, { isResponseStopEnabled }) {
		return isResponseStopEnabled;
	}
});
