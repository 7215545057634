import { take, put } from "redux-saga/effects";
import { INITIALIZE_APP, setHosts } from "../actions";
import ApplicationConfig from "@ec-oem/ec.oem.oa3.ui.common/utility/applicationConfig";
import { initializeAppCommon, initializeAppInsights, INIT_APPINSIGHTS_COMPLETE } from "@ec-oem/ec.oem.oa3.ui.common/actions";

export function* appStartSaga() {
	while (true) {
		yield take(INITIALIZE_APP);
		const config = yield ApplicationConfig.config;
		yield put(initializeAppInsights(config.appinsights_InstrumentationKey));
		yield take(INIT_APPINSIGHTS_COMPLETE);
		yield put(setHosts(getHostsArray(config.Hosts)));
		yield put(initializeAppCommon(config.Platform, config.PageRoleMapping, config.ComponentRoleMapping));
	}
}

const getHostsArray = (hosts) => {
	return Object.keys(hosts).map((key) => {
		return { ...hosts[key], name: key };
	});
};
