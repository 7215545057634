import React, { Component } from "react";
import { connect } from "react-redux";
import { Label, Stack, StackItem, Modal, IconButton, TextField, Checkbox, Text, Link, DefaultButton, PrimaryButton, Overlay, Icon, FocusTrapZone } from "@fluentui/react";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import "./FeedbackModal.scss";
import { FeedbackModalConstants, FeedbackResponseTypeOptions, FeedbackStatus } from "../../constants/SettingConstant";
import { Formik } from "formik";
import { helpLinksSelector } from "@ec-oem/ec.oem.oa3.ui.common/selectors";
import { submitFeedback } from "./ChatBot.actions";
import * as Yup from "yup";

class FeedbackModal extends Component {
	state = {
		isModalOpen: false
	};
	getInitialValues = () => {
		return {
			[FeedbackModalConstants.FEEDBACK_TEXT]: "",
			[FeedbackModalConstants.CONTACT_FOR_FEEDBACK]: true,
			[FeedbackModalConstants.FEEDBACK_INACCURATE]: false,
			[FeedbackModalConstants.FEEDBACK_LACKING_CLARITY]: false,
			[FeedbackModalConstants.FEEDBACK_OFFENSIVE]: false,
			[FeedbackModalConstants.FEEDBACK_OTHER]: false,
			[FeedbackModalConstants.FEEDBACK_UPVOTE]: null
		};
	};

	onSubmit = (values) => {
		this.setState({ isModalOpen: false });
		this.props.actions.submitFeedback(values);
	};

	getValidationSchema = (intl) => {
		return Yup.object().shape({
			[FeedbackModalConstants.FEEDBACK_TEXT]: Yup.string()
				.test(
					"Verify Message Length Validation",
					intl.formatMessage({
						id: "conversation.messageLengthError",
						defaultMessage: "The message length cannot exceed 2000 characters"
					}),
					function(message) {
						if (!message) return true;
						return message.length < FeedbackModalConstants.FEEDBACK_TEXT_MAX_CHARACTER_LENGTH;
					}
				)
				.nullable()
		});
	};

	render() {
		let {feedbackStatus} = this.props;
		return (
			(feedbackStatus == FeedbackStatus.PositveFeedback) ?
			<React.Fragment><Icon iconName="LikeSolid" style={{color : '#0078D4', 'fontSize':'16px', 'paddingRight' : '10px'}}/></React.Fragment> :
			(feedbackStatus == FeedbackStatus.NegativeFeedback) ? 
					<React.Fragment><Icon iconName="DislikeSolid" style={{ color: '#0078D4', 'fontSize': '16px', 'paddingRight': '10px' }} /></React.Fragment> :
					<Formik direction="vertical" 
				initialValues={this.getInitialValues()}
				validationSchema={() => this.getValidationSchema(this.props.intl)}
				validateOnBlur={false}
				onSubmit={(values, { resetForm }) => {
					this.onSubmit(values);
					resetForm();
				}}>
				{(formikProps) => {
					const { setFieldTouched, setFieldValue, handleSubmit, values, touched, errors, resetForm } = formikProps;

					const updateField = (key, value, touched = true) => {
						setFieldTouched(key, touched);
						setFieldValue(key, value);
					};

					return (
						<React.Fragment>
							<Stack tokens={{ childrenGap: 4 }} horizontal>
								<IconButton
									iconProps={{ iconName: "Like" }}
									title="Like Message"
									ariaLabel={this.props.intl.formatMessage({ id: "feedback.likeMessage", defaultMessage: "Like Message" })}
									onClick={(event, value) => {
										updateField(FeedbackModalConstants.FEEDBACK_UPVOTE, true);
										this.setState({ isModalOpen: true });
									}}
								/>
								<IconButton
									iconProps={{ iconName: "Dislike" }}
									title="Dislike Message"
									ariaLabel={this.props.intl.formatMessage({ id: "feedback.dislikeMessage", defaultMessage: "Dislike Message" })}
									onClick={(event, value) => {
										updateField(FeedbackModalConstants.FEEDBACK_UPVOTE, false);
										this.setState({ isModalOpen: true });
									}}
								/>
							</Stack>
							{this.state.isModalOpen &&
								<Overlay isBlocking={true} className={"feedback-overlay-modal"}>
									<FocusTrapZone ariaLabelledBy="feedbackModalSendMessage">
										<div className={`feedback-modal-content ${(values && values[FeedbackModalConstants.FEEDBACK_UPVOTE] == false) ? "downvote" : "upvote"} `}>
											<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
												<Stack horizontal className="feedback-modal-header-stack">
													<StackItem id="feedbackModalSendMessage" className="feedback-modal-title">
														{this.props.intl.formatMessage({
															id: "feedbackModal.sendFeedbackMessage",
															defaultMessage: "Send Feedback"
														})}
													</StackItem>
													<StackItem>
														<IconButton
															iconProps={{ iconName: "Cancel" }}
															ariaLabel="Close popup modal"
															onClick={(event, value) => {
																this.setState({ isModalOpen: false });
																resetForm();
															}}
														/>
													</StackItem>
												</Stack>
											</div>
											<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
												<React.Fragment>
													<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 zero-padding">
														{values && values[FeedbackModalConstants.FEEDBACK_UPVOTE] == false && (
															<Stack tokens={{ childrenGap: 8 }}>
																<StackItem>
																	<Text>
																		{this.props.intl.formatMessage({
																			id: "feedbackModal.feedbackResponse",
																			defaultMessage: "The response was"
																		})}
																	</Text>
																</StackItem>
																{FeedbackResponseTypeOptions.map((item, index) => {
																	return (
																		<StackItem>
																			<Checkbox
																				ariaLabel={"The response was" + item.labelDefaultMessage}
																				label={this.props.intl.formatMessage({
																					id: item.labelId,
																					defaultMessage: item.labelDefaultMessage
																				})}
																				checked={values && values[item.formikId]}
																				onChange={(event, checked) => {
																					updateField(item.formikId, checked);
																				}}
																			/>
																		</StackItem>
																	);
																})}
															</Stack>
														)}
													</div>
													<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 zero-padding padd-bottom10">
														<TextField
															id={FeedbackModalConstants.FEEDBACK_TEXT}
															value={values && values[FeedbackModalConstants.FEEDBACK_TEXT]}
															placeholder={
																values && values[FeedbackModalConstants.FEEDBACK_UPVOTE] == true
																	? this.props.intl.formatMessage({
																		id: "feedbackModal.positiveFeedbackPlaceholder",
																		defaultMessage: "What worked for you and what we can improve on.."
																	})
																	: this.props.intl.formatMessage({
																		id: "feedbackModal.negativeFeedbackPlaceholder",
																		defaultMessage: "Give us more detail about the issue you faced.."
																	})
															}
															label={this.props.intl.formatMessage({ id: "feedbackModal.textBoxLabel", defaultMessage: "Tell us about your experience" })}
															multiline
															onChange={(event, value) => {
																updateField(FeedbackModalConstants.FEEDBACK_TEXT, value);
															}}
														/>
													</div>
													<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 zero-padding padd-bottom10">
														<Checkbox
															label={this.props.intl.formatMessage({
																id: "feedbackModal.checkBoxMessage",
																defaultMessage: "Contact me regarding my feedback (via email)"
															})}
															checked={values && values[FeedbackModalConstants.CONTACT_FOR_FEEDBACK]}
															onChange={(event, checked) => {
																updateField(FeedbackModalConstants.CONTACT_FOR_FEEDBACK, checked);
															}}
														/>
													</div>
													<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 zero-padding">
														<Text>
															{this.props.intl.formatMessage({
																id: "feedbackModal.checkBoxMessage",
																defaultMessage:
																	"By pressing submit, your feedback will be used to improve Microsoft products and services.To know more about how this data will be used read "
															})}{" "}
															<Link target="_blank" href={this.props.helpLinks ? this.props.helpLinks.PrivacyStatement : ""}>
																{this.props.intl.formatMessage({ id: "feedbackModal.privacyStatement", defaultMessage: "Privacy Statement" })}
															</Link>
														</Text>
													</div>
													<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 zero-padding">
														<Stack horizontal className="feedback-modal-buttons-stack">
															<StackItem className="button-stack-item submit">
																<PrimaryButton
																	primary
																	allowDisabledFocus
																	onClick={handleSubmit}
																	ariaLabel={this.props.intl.formatMessage({ id: "feedbackModal.Submit", defaultMessage: "Submit" })}>
																	{this.props.intl.formatMessage({ id: "feedbackModal.Submit", defaultMessage: "Submit" })}
																</PrimaryButton>
															</StackItem>
															<StackItem className="button-stack-item cancel">
																<DefaultButton
																	allowDisabledFocus
																	onClick={(event, value) => {
																		this.setState({ isModalOpen: false });
																		resetForm();
																	}}
																	ariaLabel={this.props.intl.formatMessage({ id: "feedbackModal.Cancel", defaultMessage: "Cancel" })}>
																	{this.props.intl.formatMessage({ id: "feedbackModal.Cancel", defaultMessage: "Cancel" })}
																</DefaultButton>
															</StackItem>															
														</Stack>
													</div>
												</React.Fragment>
											</div>
										</div>
									</FocusTrapZone>								
							</Overlay>}
						</React.Fragment>
					);
				}}
			</Formik>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ submitFeedback }, dispatch)
	};
};

const mapStateToProps = (state) => {
	return {
		helpLinks: helpLinksSelector(state)
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FeedbackModal));
