import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";

export const GET_CHATGPT_RESPONSE = "COMMON/Chatbot/GET_CHATGPT_RESPONSE";
export const getChatgptResponse = makeActionCreator(GET_CHATGPT_RESPONSE, false, "message", "abortSignal");

export const SET_ABORT_CONTROLLER = "COMMON/Chatbot/SET_ABORT_CONTROLLER";
export const setAbortController = makeActionCreator(SET_ABORT_CONTROLLER, false, "abortController");

export const ADD_MESSAGES = "COMMON/Chatbot/ADD_MESSAGES";
export const addMessages = makeActionCreator(ADD_MESSAGES, false, "conversationlist");

export const ADD_TO_MESSAGEITEM_LIST = "COMMON/Chatbot/ADD_TO_MESSAGEITEM_LIST";
export const addMessageItem = makeActionCreator(ADD_TO_MESSAGEITEM_LIST, false, "messageitem");

export const SET_COUNT = "COMMON/Chatbot/SET_COUNT";
export const setCount = makeActionCreator(SET_COUNT, false, "count");

export const SET_LIST = "COMMON/Chatbot/SET_LIST";
export const setList = makeActionCreator(SET_LIST, false, "messageitemlist");

export const SET_CONVERSATION_ID = "COMMON/Chatbot/SET_CONVERSATION_ID";
export const setConversationId = makeActionCreator(SET_CONVERSATION_ID, false, "conversationid");

export const GET_COSMIC_RESPONSE = "COMMON/Chatbot/GET_COSMIC_RESPONSE";
export const getCosmicResponse = makeActionCreator(GET_COSMIC_RESPONSE, false);

export const SET_CONVERSATION_ALLOWED = "COMMON/Chatbot/SET_CONVERSATION_ALLOWED";
export const setConversationAllowed = makeActionCreator(SET_CONVERSATION_ALLOWED, false, "conversationAllowed");

export const SUBMIT_FEEDBACK = "COMMON/Chatbot/SUBMIT_FEEDBACK";
export const submitFeedback = makeActionCreator(SUBMIT_FEEDBACK, false, "submitFeedbackRequest");

export const SET_IS_RESPONSE_STOP_ENABLED = "COMMON/Chatbot/SET_IS_RESPONSE_STOP_ENABLED";
export const setIsResponseStopEnabled = makeActionCreator(SET_IS_RESPONSE_STOP_ENABLED, false, "isResponseStopEnabled");

export const GET_KEY_CATEGORIES_LIST = "COMMON/Chatbot/GET_KEY_CATEGORIES_LIST";
export const getKeyCategoriesList = makeActionCreator(GET_KEY_CATEGORIES_LIST, false);

export const SET_KEY_CATEGORIES_LIST = "COMMON/Chatbot/SET_KEY_CATEGORIES_LIST";
export const setKeyCategoriesList = makeActionCreator(SET_KEY_CATEGORIES_LIST, false, 'keyCategoriesList');

