/*
 *
 * Notification reducer
 *
 */

import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { fromJS } from "immutable";
import * as NotificationCenterAction from "./Notification.actions";

export const allNotifications = createReducer(null, {
	[NotificationCenterAction.SET_ALL_NOTIFICATIONS](state, { allNotifications }) {
		return fromJS(allNotifications);
	}
});
