import MarkdownResponse from "./MarkdownResponse/MarkdownResponse";
import Support from "./Cosmic/Support";
import { Stack, StackItem } from "@fluentui/react";
import FeedbackModal from "./FeedbackModal";
import { FeedbackStatus } from "../../constants/SettingConstant";

const keywords = ["I'm not aware of the answer"];

export const appendList = (message, name, initials, c, showCosmicButton, showFeedbackOptions, suggestedQuestions, isSupportAssistanceRequired, feedbackStatus ) => {
	const isMatch = keywords.some((keyword) => message.toLowerCase().includes(keyword.toLowerCase())) || isSupportAssistanceRequired;
	const cosmicPresent = showCosmicButton && isMatch;
	return {
		key: c,
		index:0,
		cosmicPresentKey: cosmicPresent,
		suggestedQuestions: suggestedQuestions,
		feedbackPresentKey: showFeedbackOptions,
		// activityDescription: [
		// 	<span key={1} className="nameText">
		// 		{" "}
		// 		{name}
		// 	</span>
		// ],
		activityPersonas: [{ imageInitials: "", text: initials, initialsColor: "" }],
		commentText: message,
		comments: [
			<span key={1} className="messageText">
				<MarkdownResponse  content={message} />
				<Stack horizontal className="response-footer-stack">
					<StackItem>{showCosmicButton && isMatch && <Support></Support>}</StackItem>
					<StackItem>{(showFeedbackOptions || feedbackStatus == FeedbackStatus.PositveFeedback || feedbackStatus == FeedbackStatus.NegativeFeedback) && <FeedbackModal feedbackStatus={feedbackStatus} />}</StackItem>
				</Stack>
			</span>
		]
		// timeStamp: getTimeStamp()
	};
};
