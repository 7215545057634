/*
 *
 * Notification actions
 *
 */

import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const GET_ALL_NOTIFICATIONS = "common/components/DeviceSelectionStep/GET_ALL_NOTIFICATIONS";
export const getAllNotifications = makeActionCreator(GET_ALL_NOTIFICATIONS, false, "alertSearchCriteria");

export const SET_ALL_NOTIFICATIONS = "common/components/messageCenter/notification/SET_ALL_NOTIFICATIONS";
export const setAllNotifications = makeActionCreator(SET_ALL_NOTIFICATIONS, false, "allNotifications");

export const MARKREAD_SELETED_NOTIFICATIONS = "components/MessageCenter/Notification/MARKREAD_SELETED_NOTIFICATIONS";
export const markReadNotifications = makeActionCreator(MARKREAD_SELETED_NOTIFICATIONS, false, "readAlerts", "selectedPageSize");

export const DELETE_SELETED_NOTIFICATIONS = "components/MessageCenter/Notification/DELETE_SELETED_NOTIFICATIONS";
export const deleteNotifications = makeActionCreator(DELETE_SELETED_NOTIFICATIONS, false, "deleteAlerts", "selectedPageSize");
