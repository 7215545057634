import React from "react";
import { injectIntl } from "react-intl";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

function MarkdownResponse(props) {

    return (
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw, rehypeSanitize]}
        >
          {props.content ?? ''}
        </ReactMarkdown>
      );
}

export default injectIntl(MarkdownResponse);