import { createSelector } from "reselect";

export const getMessages = createSelector(
	(state) => state.app.get("conversationlist"),
	(conversationlist) => (conversationlist ? conversationlist.slice(-3) : [])
);

export const getAllMessages = createSelector(
	(state) => state.app.get("conversationlist"),
	(conversationlist) => (conversationlist ? conversationlist : [])
);

export const getList = createSelector(
	(state) => state.app.get("messageitemlist"),
	(messageitemlist) => (messageitemlist ? messageitemlist.toJS() : [])
);

export const getCount = createSelector(
	(state) => state.app.get("count"),
	(count) => (count ? count : 1)
);

export const getConversationId = createSelector(
	(state) => state.app.get("conversationid"),
	(conversationid) => (conversationid ? conversationid : null)
);

export const getAbortController = createSelector(
	(state) => state.app.get("abortController"),
	(abortController) => (abortController ? abortController : abortController)
);

export const getConversationAllowed = createSelector(
	(state) => state.app.get("conversationAllowed"),
	(conversationAllowed) => (conversationAllowed == false ? false : true)
);

export const isResponseStopEnabledSelector = createSelector(
	(state) => state.app.get("isResponseStopEnabled"),
	(isResponseStopEnabled) => (isResponseStopEnabled == false ? false : true)
);

export const keyCategoriesListSelector = createSelector(
	(state) => state.app.get("keyCategoriesList"),
	(keyCategoriesList) => (keyCategoriesList ? keyCategoriesList.toJS() : keyCategoriesList)
);
