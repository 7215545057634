import en_US from "./en/common.json";
import zh_CN from "./zh-CN/common.json";
import zh_TW from "./zh-TW/common.json";
import es_ES from "./es-ES/common.json";
import ja_JP from "./ja-JP/common.json";
import ko_KR from "./ko-KR/common.json";
import pt_BR from "./pt-BR/common.json";
import ru_RU from "./ru-RU/common.json";
export const localizationData = {
	"en-US": en_US,
	"zh-CN": zh_CN,
	"zh-TW": zh_TW,
	"es-ES": es_ES,
	"ja-JP": ja_JP,
	"ko-KR": ko_KR,
	"pt-BR": pt_BR,
	"ru-RU": ru_RU
};
